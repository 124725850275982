// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
html,
body {
  // font-family: 'Roboto', sans-serif;
  font-family: 'Inter', sans-serif;
  // display: flex;
  // min-height: 100vh;
  // height: 100%;
  // margin: auto;
  overflow:auto;
  // flex-direction: column;
  // color:#fff
}

.disabled{
  cursor:not-allowed!important;
  pointer-events: all!important;
}

button {
  &:focus {
    outline: none;
}
}

a {
  &:hover {
     text-decoration: none;
     color:inherit;

  }
}

.cursor-pointer{
  cursor:pointer
}


.table-center td {
  vertical-align: middle !important;
}


.dropDown-button-fullWidth {
  width: 100%;
  .dropdown {
    width: 100%;
    button {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
    }
  }
}

#toast-container {
  .toast {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12) !important;
    opacity: 1 !important;
    border-radius: 0.25rem !important;
    width: 400px !important;
    border: unset !important;

    &.toast-info {
      background-color: #2bbbad !important;
    }
    &.toast-success {
      background-color: #2bbbad !important;
    }
    &.toast-error {
      background-color: #ff3547 !important;
    }
  }
}

.modal {
  overflow-y: scroll;
}
